<template>
  <v-dialog max-width="800px" v-model="dialog">
    <template #activator="{ on }">
      <v-list rounded>
        <v-list-item-group mandatory :value="0">
          <branch-item-preview :item="obBranch" v-on="on" />
        </v-list-item-group>
      </v-list>
    </template>

    <branch-form
      v-if="dialog"
      @cancel="close"
      @deleted="saved"
      @saved="saved"
      v-model="obBranch"
      :secondary="secondary"
      is-branch
    />
  </v-dialog>
</template>
<script lang="ts">
import type { FirmData } from "@planetadeleste/vue-mc-gw";
import { Branch } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel, Prop, Watch } from "vue-property-decorator";
import BranchForm from "@/modules/companies/components/BranchForm.vue";
import Utils from "@/services/Utils";
import BranchItemPreview from "@/modules/companies/components/BranchItemPreview.vue";

@Component({
  components: { BranchItemPreview, BranchForm },
})
export default class BranchFormDialog extends Vue {
  @VModel({ type: Object, default: () => new Branch() }) obBranch!: Branch;
  @Prop(Boolean) readonly secondary!: boolean;

  @Watch("dialog")
  onChangeDialog(bValue: boolean) {
    if (!bValue || this.obBranch.id) {
      return;
    }

    Utils.nextCode("branch").then((response) => {
      this.$set(this.obFirm, "code", response.data);
    });
  }

  dialog = false;

  close() {
    this.$emit("input", new Branch());
    this.dialog = false;
  }

  saved() {
    this.$emit("saved");
    this.close();
  }

  get obFirm(): FirmData {
    return this.obBranch.get("firm", {});
  }
}
</script>
