<template>
  <v-row>
    <v-col cols="12">
      <form-field-text
        v-model="obFirm.name"
        label="name"
        required
        @save="onSave"
        @input:debounce="onUpdateName"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";

@Component
export default class CustomerBasics extends Mixins(CustomerMixin) {
  onUpdateName(sValue: string) {
    this.$set(this.obFirm, "dgi_denominacion", sValue);
  }
}
</script>
