<template>
  <v-row>
    <!-- <v-col cols="12" md="6">
      <form-field-text
        input-type="email"
        label="customer.email"
        v-model="obConfig.mail"
      />
    </v-col> -->
    <v-col cols="12">
      <form-field-text
        label="customer.contact.name"
        v-model="obConfig.contact_name"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <form-field-text
        label="customer.commercial.phone"
        v-model="obConfig.phone_com"
      />
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <form-field-text
        label="customer.admin.phone"
        v-model="obConfig.phone_admin"
      />
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <form-field-text
        label="customer.account.phone"
        v-model="obConfig.phone_account"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";
import { isArray } from "lodash";

@Component
export default class CustomerExtra extends Mixins(CustomerMixin) {
  mounted() {
    if (isArray(this.obConfig)) {
      this.setData("config", {});
    }
  }
}
</script>
